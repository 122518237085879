import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserAccountGuard } from './route-guards/user-account.guard';
import { DashBoardGuard } from './route-guards/dash-board.guard';
import { CanDeactiveProductGuard } from './route-guards/can-deactive-product.guard';
import { GpayComponent } from './pages/gpay/gpay.component';
import { CustomPreloadingStrategyService } from './services/custom-preloading-strategy.service';
import { RedirectGuard } from './route-guards/redirect.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '/',
        redirectTo: '',
        pathMatch: 'full',
      },
      {
        path: '',
        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomePageModule),
      },
      {
        path: 'account',
        loadChildren: () =>
          import('./pages/account/account.module').then(
            (m) => m.AccountPageModule
          ),
        // data:{preload: true, delay:1500}
      },
      //  CanActivateChild UserAccountGuard route apply
      {
        path: '',
        canActivateChild: [UserAccountGuard],
        children: [
          // {
          //   path: 'sold',
          //   loadChildren: () =>
          //     import('../pages/product/sold/sold.module').then(
          //       (m) => m.SoldPageModule
          //     ),
          // },
          // {
          //   path: 'seller/:seller/groups/:id',
          //   loadChildren: () =>
          //     import('./pages/product/group/group.module').then(
          //       (m) => m.GroupPageModule
          //     ),
          // },
          {
            path: 'post',
            loadChildren: () =>
              import('./pages/post/post.module').then((m) => m.PostPageModule),
          },
          // {
          //   path: 'orders',
          //   loadChildren: () =>
          //     import('../pages/orders/orders.module').then(
          //       (m) => m.OrdersPageModule
          //     ),
          // },
          // {
          //   path: 'orders/details/:orderId',
          //   loadChildren: () =>
          //     import('../pages/orders/details/details.module').then(
          //       (m) => m.DetailsPageModule
          //     ),
          // },
          // {
          //   path: 'report/sales',
          //   loadChildren: () =>
          //     import('../pages/reports/sales/sales.module').then(
          //       (m) => m.SalesPageModule
          //     ),
          // },
          {
            path: 'notifications',
            loadChildren: () =>
              import('./pages/notifications/notifications.module').then(
                (m) => m.NotificationsPageModule
              ),
          },
          {
            path: 'category',
            loadChildren: () =>
              import('./pages/category/category.module').then(
                (m) => m.CategoryPageModule
              ),
          },
          {
            path: 'selller-reg',
            loadChildren: () =>
              import('./pages/selller-reg/selller-reg.module').then(
                (m) => m.SelllerRegPageModule),
          },
          // {
          //   path: 'login',
          //   loadChildren: () =>
          //     import('../pages/auth/login/login.module').then(
          //       (m) => m.LoginPageModule
          //     ),
          // },
          {
            path: 'dashboard/buyer',
            loadChildren: () =>
              import('./pages/dashboard/buyer/buyer.module').then(
                (m) => m.BuyerPageModule
              ),
          },
          {
            path: 'profile/seller',
            loadChildren: () =>
              import('./pages/profile/seller/seller.module').then(
                (m) => m.SellerPageModule
              ),
          },
          {
            path: 'profile/seller/:type',
            loadChildren: () =>
              import('./pages/profile/seller/seller.module').then(
                (m) => m.SellerPageModule
              ),
          },

          // {
          //   path: 'profile/buyer',
          //   loadChildren: () =>
          //     import('../pages/profile/user/user.module').then(
          //       (m) => m.UserPageModule
          //     ),
          // },
          {
            path: 'color',
            loadChildren: () =>
              import('./pages/product/color/color.module').then(
                (m) => m.ColorPageModule
              ),
          },
          {
            path: 'password/change',
            loadChildren: () =>
              import('./pages/auth/password/change/change.module').then(
                (m) => m.ChangePageModule
              ),
          },
          {
            path: 'product/list',
            loadChildren: () =>
              import('./pages/product/list/list.module').then(
                (m) => m.ListPageModule
              ),
          },
          {
            path: 'post/view/:id',
            loadChildren: () =>
              import('./pages/post/view/view.module').then(
                (m) => m.ViewPageModule
              ),
          },
          {
            path: 'details',
            loadChildren: () =>
              import('./pages/product/details/details.module').then(
                (m) => m.DetailsPageModule
              ),
          },
          {
            path: 'gallery',
            loadChildren: () =>
              import('./pages/gallery/gallery.module').then(
                (m) => m.GalleryPageModule
              ),
          },
          {
            path: 'wishlist',
            loadChildren: () =>
              import('./pages/wishlist/wishlist.module').then(
                (m) => m.WishlistPageModule
              ),
          },
          {
            path: 'size-picker',
            loadChildren: () =>
              import('./utils/size-picker/size-picker.module').then(
                (m) => m.SizePickerPageModule
              ),
          },
          {
            path: 'address',
            loadChildren: () =>
              import('./pages/address/address.module').then(
                (m) => m.AddressPageModule
              ),
          },
          // {
          //   path: 'address/edit/:id',
          //   loadChildren: () =>
          //     import('../pages/address/address.module').then(
          //       (m) => m.AddressPageModule
          //     ),
          // },
          {
            path: 'orders',
            loadChildren: () =>
              import('./pages/orders/orders.module').then(
                (m) => m.OrdersPageModule
              ),
          },
          // {
          //   path: 'orders/details/:orderId',
          //   loadChildren: () =>
          //     import('../pages/orders/details/details.module').then(
          //       (m) => m.DetailsPageModule
          //     ),
          // },
          {
            path: 'orders/track/:orderId',
            loadChildren: () =>
              import('./pages/orders/track/track.module').then(
                (m) => m.TrackPageModule
              ),
          },
          // {
          //   path: 'seller/:seller/groups/:id',
          //   loadChildren: () =>
          //     import('./pages/product/group/group.module').then(
          //       (m) => m.GroupPageModule
          //     ),
          // },
          {
            path: 'user/profile/:id',
            loadChildren: () =>
              import('./pages/account/account.module').then(
                (m) => m.AccountPageModule
              ),
          },
          {
            path: 'products/affiliated',
            loadChildren: () =>
              import('./pages/product/affiliated/affiliated.module').then(
                (m) => m.AffiliatedPageModule
              ),
          },
          {
            path: 'affiliated/all',
            loadChildren: () =>
              import('./pages/product/all/all.module').then(
                (m) => m.AllPageModule
              ),
          },

          {
            path: 'bulk-csv',
            loadChildren: () =>
              import('./utils/bulk-csv/bulk-csv.module').then(
                (m) => m.BulkCsvPageModule
              ),
          },
          {
            path: 'products/seller/:id',
            loadChildren: () =>
              import('./pages/product/seller/seller.module').then(
                (m) => m.SellerPageModule
              ),
          },
          {
            path: 'posts/seller/:id',
            loadChildren: () =>
              import('./pages/post/seller/seller.module').then(
                (m) => m.SellerPageModule
              ),
          },
          {
            path: 'notifications',
            loadChildren: () =>
              import('./pages/notifications/notifications.module').then(
                (m) => m.NotificationsPageModule
              ),
          },
          {
            path: 'account/setting',
            loadChildren: () =>
              import('../app/pages/account/account-setting//account-setting.module').then((m) => m.AccountSettingModule),
          },
        ]
      },
      // canActivateChild DashBoardGuard Route Apply
      {
        path: '',
        canActivateChild: [DashBoardGuard],
        children: [
          {
            path: 'dashboard/seller',
            loadChildren: () =>
              import('./pages/dashboard/seller/seller.module').then(
                (m) => m.SellerPageModule
              ),
            // data:{preload: true, delay:1500}
          },
          {
            path: 'dashboard/seller/products/group',
            loadChildren: () =>
              import('./pages/product/group/group.module').then(
                (m) => m.GroupPageModule
              ),
          },
          {
            path: 'seller',
            loadChildren: () =>
              import('./pages/dashboard/seller/seller.module').then(
                (m) => m.SellerPageModule
              ),
          },
          {
            path: 'dashboard/seller/product/sizes',
            loadChildren: () =>
              import('./pages/product/sizes/sizes.module').then(
                (m) => m.SizesPageModule
              ),
          },
          {
            path: 'dashboard/seller/product/product-price-control',
            loadChildren: () =>
              import(
                './pages/product/product-price-control/product-price-control.module'
              ).then((m) => m.ProductPriceControlModule),
          },
          {
            path: 'dashboard/seller/products/manage/edit/:type',
            loadChildren: () =>
              import('./pages/product/add/add.module').then(
                (m) => m.AddPageModule
              ),
          },
          {
            path: 'dashboard/seller/orders/track/:orderId',
            loadChildren: () =>
              import('./pages/orders/track/track.module').then(
                (m) => m.TrackPageModule
              ),
          },
          {
            path: 'dashboard/reseller-products',
            loadChildren: () =>
              import('./pages/product/affiliated/affiliated.module').then(
                (m) => m.AffiliatedPageModule
              ),
          },
          {
            path: 'affiliated/all',
            loadChildren: () =>
              import('./pages/product/all/all.module').then(
                (m) => m.AllPageModule
              ),
          },
          {
            path: 'dashboard/reseller-commission',
            loadChildren: () =>
              import(
                './pages/product/affiliated/commission/commission.module'
              ).then((m) => m.CommissionPageModule),
          },
          {
            path: 'raise-dispute',
            loadChildren: () =>
              import(
                '././pages/orders/seller/raise-dispute/raise-dispute.module'
              ).then((m) => m.RaiseDisputeModule),
          },
          {
            path: 'claim-dispute',
            loadChildren: () =>
              import('./pages/dashboard/dispute/claim-dispute.module').then(
                (m) => m.ClaimDisputeModule
              ),
          },
          {
            path: 'claim-dispute/conversation/:id',
            loadChildren: () =>
              import('././pages/dashboard/dispute/conversation/conversation.module').then(
                (m) => m.ConversationModule
              ),
          },
          {
            path: 'Stripe-Payment',
            loadChildren: () =>
              import('./tab1/tab1.module').then((m) => m.Tab1PageModule),
          },
          // {
          //   path: 'Shopify-store',
          //   loadChildren: () =>
          //     import('./pages/dashboard/shopify-store/shopify-store.module').then(
          //       (m) => m.ShopifyStoreModule
          //     ),
          // },
          // {
          //   path: 'Add-Shopify-store',
          //   loadChildren: () =>
          //     import('./pages/dashboard/shopify-store/connect-shop/add-credential.module').then(
          //       (m) => m.AddCredentialModule
          //     ),
          // },
          // {
          //   path: 'Add-Shopify-product',
          //   loadChildren: () =>
          //     import('./pages/dashboard/shopify-store/add-product/shopify-product.module').then(
          //       (m) => m.ShopifyProductModule
          //     ),
          // },
          {
            path: 'payments',
            loadChildren: () =>
              import('./pages/payments/payments.module').then(
                (m) => m.PaymentsPageModule
              ),
          },
          {
            path: 'dashboard/shop',
            loadChildren: () =>
              import('./pages/dashboard/shop/shop.module').then(
                (m) => m.ShopPageModule
              ),
          },
          {
            path: 'dashboard/shop/:id',
            loadChildren: () =>
              import('./pages/dashboard/shop/shop.module').then(
                (m) => m.ShopPageModule
              ),
          },
          {
            path: 'sold',
            loadChildren: () =>
              import('./pages/product/sold/sold.module').then(
                (m) => m.SoldPageModule
              ),
          },
          {
            path: 'post/image',
            loadChildren: () =>
              import('./pages/post/image/image.module').then(
                (m) => m.ImagePageModule
              ),
          },
          {
            path: 'post/edit/:type',
            loadChildren: () =>
              import('./pages/post/image/image.module').then(
                (m) => m.ImagePageModule
              ),
          },
          {
            path: 'account/support',
            loadChildren: () =>
              import('./pages/Support/watch-and-learn.module').then(
                (m) => m.WatchAndLearnPageModule
              ),
          },
          {
            path: 'dashboard/seller/product/add',
            canDeactivate: [CanDeactiveProductGuard],
            loadChildren: () =>
              import('./pages/product/add/add.module').then(
                (m) => m.AddPageModule
              ),
          },
          {
            path: 'dashboard/seller/product/settings',
            loadChildren: () =>
              import('./pages/product/settings/settings.module').then(
                (m) => m.SettingsPageModule
              ),
          },
          {
            path: 'dashboard/seller/orders',
            loadChildren: () =>
              import('./pages/orders/seller/seller.module').then(
                (m) => m.SellerPageModule
              ),
          },
          {
            path: 'report/sales',
            loadChildren: () =>
              import('./pages/reports/sales/sales.module').then(
                (m) => m.SalesPageModule
              ),
          },
          {
            path: 'warehouse',
            loadChildren: () =>
              import('./pages/warehouse/warehouse.module').then(
                (m) => m.WarehousePageModule
              ),
          },
          {
            path: 'dashboard/seller/products/manage',
            loadChildren: () =>
              import('./pages/product/manage/manage.module').then(
                (m) => m.ManagePageModule
              ),
          },
          {
            path: 'dashboard/seller/products/outofstock',
            loadChildren: () =>
              import('./pages/product/out-of-stock/out-of-stock.module').then(
                (m) => m.OutOfStockModule
              ),
          },
          {
            path: 'dashboard/seller/products/manage/:category',
            loadChildren: () =>
              import('./pages/product/manage/manage.module').then(
                (m) => m.ManagePageModule
              ),
          },
          {
            path: 'add_meeshow_products',
            loadChildren: () =>
              import('./pages/product/add-meesho-product/add-meesho-product.module').then(
                (m) => m.AddMeeshoProductModule
              ),
          },
          {
            path: 'dashboard/accounts',
            loadChildren: () => import('./pages/dashboard/accounts/accounts.module').then((m) => m.AccountsModule),
          },
          {
            path: 'dashboard/seller-insignt',
            loadChildren: () =>
              import('../app/pages/dashboard/seller-insignts/seller-insignts.module').then((m) => m.SellerInsigntsModule),
          },
        ]
      },

      // Without route guard components 
      {
        path: 'new-admin-support',
        loadChildren: () =>
          import('./pages/admin-support/admin-support.module').then(
            (m) => m.AdminSupportModule
          ),
      },
      {
        path: 'seller/:seller/:groupName/:id',
        loadChildren: () =>
          import('./pages/product/group/group.module').then(
            (m) => m.GroupPageModule
          ),
      },
      {
        path: 'profile/shop/:id',
        loadChildren: () =>
          import('./pages/dashboard/shop/shop.module').then(
            (m) => m.ShopPageModule
          ),
      },
      {
        path: 'post-details/:id',
        loadChildren: () =>
          import('./pages/post-details/post-details.module').then(
            (m) => m.PostDetailsModule
          ),
      },
      {
        path: 'bag',
        loadChildren: () =>
          import('./pages/bag/bag.module').then((m) => m.BagPageModule),
      },
      {
        path: 'buy_now',
        loadChildren: () =>
        import('./pages/buy-bag/buy-now-bag.module').then((m) => m.BuyNowBagModule),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./pages/search/search.module').then(
            (m) => m.SearchPageModule
          ),
      },
      {
        path: 'products/categories/:category/:catId',
        loadChildren: () =>
          import('./pages/product/seller/seller.module').then(
            (m) => m.SellerPageModule
          ),
      },
      {
        path: 'search/explore',
        loadChildren: () =>
          import('./pages/search/explore/explore.module').then(
            (m) => m.ExplorePageModule
          ),
      },
      {
        path: 'products/view/:id',
        loadChildren: () =>
          import('./pages/product/view/view.module').then(
            (m) => m.ViewPageModule
          ),
      },
      {
        path: 'products/view/:sid/:id',
        loadChildren: () =>
          import('./pages/product/view/view.module').then(
            (m) => m.ViewPageModule
          ),
      },
      {
        path: 'products/view/:id/:variant',
        loadChildren: () =>
          import('./pages/product/view/view.module').then(
            (m) => m.ViewPageModule
          ),
      },
      {
        path: 'categories',
        loadChildren: () =>
          import('./pages/categories/categories.module').then(
            (m) => m.CategoriesPageModule
          ),
      },
      // {
      //   path: 'otp',
      //   loadChildren: () =>
      //     import('../pages/auth/otp/otp.module').then((m) => m.OtpPageModule),
      // },       
      {
        path: 'loginRegistration',
        loadChildren: () =>
          import('./pages/auth/reg/reg.module').then((m) => m.RegPageModule),
      },
      // {
      //   path: 'privacy-policy',
      //   loadChildren: () =>
      //     import('../pages/privacy-policy/privacy-policy.module').then(
      //       (m) => m.PrivacyPolicyPageModule
      //     ),
      // },
      // {
      //   path: 'returns-and-refunds',
      //   loadChildren: () =>
      //     import(
      //       '../pages/returns-and-refunds/returns-and-refunds.module'
      //     ).then((m) => m.ReturnsAndRefundsPageModule),
      // },
      // {
      //   path: 'shipping-and-delivery',
      //   loadChildren: () =>
      //     import(
      //       '../pages/shipping-and-delivery/shipping-and-delivery.module'
      //     ).then((m) => m.ShippingAndDeliveryModule),
      // },
      // {
      //   path: 'contact-us',
      //   loadChildren: () =>
      //     import('../pages/contact-us/contact-us.module').then(
      //       (m) => m.ContactUsModule
      //     ),
      // },
      // {
      //   path: 'about-us',
      //   loadChildren: () =>
      //     import('../pages/about-us/about-us.module').then(
      //       (m) => m.AboutUsPageModule
      //     ),
      // },
      {
        path: 'terms-and-conditions',
        loadChildren: () =>
          import(
            './pages/terms-and-conditions/terms-and-conditions.module'
          ).then((m) => m.TermsAndConditionsPageModule),
      },
      {
        path: 'faq',
        loadChildren: () =>
          import('./pages/faq/faq.module').then((m) => m.FaqPageModule),
      },
      {
        path: 'm-privacy-policy',
        loadChildren: () =>
          import('../app/m-privacy-policy/m-privacy-policy.module').then((m) => m.MPrivacyPolicyModule),
      },
      {
        path: 'account-status',
        loadChildren: () =>
          import('../app/tab3/tab3-routing.module').then((m) => m.Tab3PageRoutingModule),
      },
      {
        path: 'gpay', component: GpayComponent
      },
          {
        path: '**',
        canActivate: [RedirectGuard],
        loadChildren: () =>
          import('./pages/live/live.module').then((m) => m.LiveModule),
      },
    ],
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: CustomPreloadingStrategyService }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
