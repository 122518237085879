import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Order } from '../interfaces/order';
import { Post } from '../interfaces/post';
import { Product } from '../interfaces/product';
import { Warehouse } from '../interfaces/warehouse';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ResolverService {
  apiURL: string = '';
  proxyApiUrls:string='';
  funnelID: number = 0;
  public fileUploadURL: string = `${environment.apiURL}/files`;
  constructor(private http: HttpClient) {
    this.apiURL = environment.apiURL;
    this.proxyApiUrls=environment.proxyApiUrl;
  }
  /**
   *
   * @param data
   * @returns
   */
  public checkBuyerNameAvailability(data) {
    return this.http.post(
      `${this.apiURL}/users/check-username-availability`,
      data
    );
  }
  /**
  /**
   * 
   * @param data 
   * @returns 
   */
  public checkUserNameAvailability(data) {
    return this.http.post(
      `${this.apiURL}/seller/check-username-availability`,
      data
    );
  }
  /**
   *
   * @param item
   * @param data
   * @returns the data related to the particular end point for the role based user
   */
  public postAuth(item, data) {
    return this.http.post(`${this.apiURL}/users/${item}`, data);
  }

  /**
   *
   * @param item
   * @param data
   * @returns
   */
  public patchAuth(item, data) {
    return this.http.patch(`${this.apiURL}/users/${item}`, data);
  }

  /**
   *
   * @param item
   * @returns the data related to the particular end point for the role based user
   */
  public getAuth(item) {
    return this.http.get(`${this.apiURL}/users/${item}`);
  }

  /**
   *
   * @param id
   * @param data
   * @returns
   */
  public saveBuyerInfo(data) {
    return this.http.patch(`${this.apiURL}/users`, data);
  }

  // userInformation new api
  public getUserInfo(data) {
    return this.http.post(`${this.apiURL}/users/userRegistration`, data);
  }
  /**
   *
   * @param data
   * @returns
   */
  public postSellerInfo(item, data) {
    return this.http.post(`${this.apiURL}/seller/${item}`, data);
  }

  /**
   *
   * @param data
   * @returns
   */
  public getSellerInfo() {
    return this.http.get(`${this.apiURL}/seller/get-info`);
  }

  /**
   * @description get general user/buyer info
   * @returns
   */
  public getBuyerInfo() {
    return this.http.get(`${this.apiURL}/users/me`);
  }

  /**
   *
   * @param id
   * @returns
   */
  public getSellerProfile(id: number) {
    return this.http.get(`${this.apiURL}/seller/${id}/profile`);
  }
  /**
   *
   * @returns
   */
  public getStates() {
    return this.http.get(`${this.apiURL}/states`);
  }

  /**
   *
   * @param purpose
   * @param data
   * @returns
   */
  public uploadFile(purpose: number, data: any) {
    return this.http.post(`${this.fileUploadURL}/${purpose}`, data);
  }

  /**
   *
   * @returns
   */
  public getAllProducts() {
    return this.http.get(`${this.apiURL}/products`);
  }

  /**
   *
   * @returns
   */
  public getColors() {
    return this.http.get(`${this.apiURL}/colors`);
  }

  /**
   *
   * @returns
   */
  public getWeights() {
    return this.http.get(`${this.apiURL}/weights`);
  }

  public getHSNCode() {
    return this.http.get(`${this.apiURL}/hsncodes`);
  }

  public getAllGST() {
    return this.http.get(`${this.apiURL}/taxes`);
  }

  /**
   *
   * @returns
   */
  public getCategories() {
    return this.http.get(`${this.apiURL}/categories`);
  }


  /**
   *
   * @param id
   * @returns
   */
  public getSubCategories(id: string) {
    return this.http.get(`${this.apiURL}/categories/${id}/subcategories`);
  }
  public getSearchedCategory(query:string){
    return this.http.get(`${this.apiURL}/categories/subsubCategory?searchStr=${query}`)
  }

  public getIdCategories(id:string){
    return this.http.get(`${this.apiURL}/categories/${id}`)
  }

  public getCategoriesByProduct(catName:string, start:number=0, length:number=10){
    return this.http.get(`${this.apiURL}/productsByCategoryId?categoryId=${catName}&start=${start}&length=${length}`)
  }
  /**
   *
   * @returns
   */
  public getSizes() {
    return this.http.get(`${this.apiURL}/sizes`);
  }

  /**
   *
   * @returns
   */
  public getSizeBundles() {
    return this.http.get(`${this.apiURL}/bundles`);
  }
  /**
   *
   * @param data
   * @returns
   */
  public saveProduct(data) {
    return this.http.post(`${this.apiURL}/products`, data);
  }

  /**
   *
   * @param id
   * @returns
   */
  public getProductDetails(id: string, sellerId?: string) {
    id = id?.split('?')[0];
    let query = JSON.stringify({ sellerId: parseInt(sellerId) });
    return this.http.get(`${this.apiURL}/products/${id}?query=${query}`);
  }

  /**
   *
   * @param id
   * @param data
   * @returns
   */
  public updateProductDetails(id: string, data: Product) {
    return this.http.patch(`${this.apiURL}/products/${id}`, data);
  }
  /**
   * @description returns active product list
   * @returns
   */
  public getProducts(searchStr: string = '',start: number = 0) {
    return this.http.get(
      `${this.apiURL}/myproducts/active?searchStr=${searchStr}&start=${start}&length=30`
    );
  }
  /**
   * @description returns inactive product list
   * @returns
   */
  public getInactiveProducts(searchStr: string = '',start: number = 0) {
    return this.http.get(
      `${this.apiURL}/myproducts/inactive?searchStr=${searchStr}&start=${start}&length=20`
    );
  }
  /**
   * @description returns promoted product list
   * @returns
   */
  public getPromotedProducts(searchStr: string = '',start: number = 0) {
    return this.http.get(
      `${this.apiURL}/myproducts/promoted?searchStr=${searchStr}&start=${start}&length=20`
    );
  }
  public getImageUrl(data) {
    return this.http.post(
      `${this.apiURL}/imageurl`,data);
  }

  /**
   *
   * @returns
   */
  public getSellerOwnProducts(start:number = 0, length:number = 30) {
    return this.http.get(`${this.apiURL}/myproducts?start=${start}&length=${length}`);
  }
  
  public getSellerOwnProductss(start:number = 0, length:number = 30) {
    return this.http.get(`${this.apiURL}/myproductss?start=${start}&length=${length}`);
  }
  /**
   *
   * @param id
   * @returns
   */
  public markProductActive(id: string) {
    return this.http.patch(`${this.apiURL}/products/${id}/activate`, {});
  }
  /**
   *
   * @param id
   * @returns
   */
  public markProductInactive(id: string) {
    return this.http.patch(`${this.apiURL}/products/${id}/deactivate`, {});
  }

  /**
   *
   * @param id
   * @returns
   */
  public markProductPromoted(id: string) {
    return this.http.patch(`${this.apiURL}/products/${id}/promote`, {});
  }

  /**
   *
   * @param id
   * @returns
   */
  public markProductUnpromoted(id: string) {
    return this.http.patch(`${this.apiURL}/products/${id}/unpromote`, {});
  }
  /**
   *
   * @param pincode
   * @returns
   */
  public getPostalPincode(pincode: number) {
    return this.http.get(`${this.apiURL}/postalpincode/${pincode}`);
  }

  public getPostalPinCode(pincode: number) {
    return this.http.post(`${this.apiURL}/check-pincode/${pincode}`,'');
  }
  /**
   *
   * @param name
   * @returns
   */
  public addNewSize(name: string) {
    return this.http.post(`${this.apiURL}/sizes`, { name: name });
  }

  /**
   *
   * @param post
   * @returns
   */
  public addNewPost(post) {
    return this.http.post(`${this.apiURL}/posts`, post);
  }
  // @post('/users/sendemail')
  public sendmail(mail) {
    return this.http.post(`${this.apiURL}/users/sendemail`, mail);
  }

  public patchprod(id){
    return this.http.patch(`${this.apiURL}/postsseries/${id}`,id);
  }
  /**
   *
   * @param id
   * @param comment
   * @returns
   */
  public addNewcomment(id: number, comment) {
    return this.http.post(`${this.apiURL}/posts/${id}/comments`, comment);
  }

  /**
   *
   * @param id
   * @returns
   */
  public addNewLike(id: number) {
    return this.http.post(`${this.apiURL}/posts/${id}/likes`, {});
  }

  /**
   *
   * @param id
   * @returns
   */
  public addNewFollow(id: number) {
    return this.http.post(`${this.apiURL}/sellers/${id}/follows`, {});
  }

  /**
   *
   * @param id
   * @param commentId
   * @returns
   */
  public addLikesByCommentId(id: number, commentId: number) {
    return this.http.post(
      `${this.apiURL}/posts/comments/${commentId}/likes`,
      {}
    );
  }

  /**
   *
   * @param id
   * @returns
   */
  public getAllFollowers(id: number) {
    return this.http.post(`${this.apiURL}/sellers/${id}/follows`, {});
  }

  /**
   * @description get list of followed sellers
   * @returns
   */
  public getAllFollowings() {
    return this.http.get(`${this.apiURL}/users/followings`);
  }
  /**
   *
   * @returns
   */
  public getAllPosts(start: number = 0, length: number = 30) {
    if (start == -1) {
      return this.http.get(`${this.apiURL}/posts`);
    }
    return this.http.get(
      `${this.apiURL}/posts?start=${start}&length=${length}`
    );
  }

  /**
   * @description get user(seller) own posts
   * @returns
   */
  public getPosts(start:number = 0, length:number = 30) {
    return this.http.get(`${this.apiURL}/myposts?start=${start}&length=${length}`);
  }

  /**
   *
   * @param id
   * @returns
   */
  public getCommentsByPostId(id: number) {
    return this.http.get(`${this.apiURL}/posts/${id}/comments`);
  }

  /**
   *
   * @param id
   * @returns
   */
  public getLikesByPostId(id: number) {
    return this.http.get(`${this.apiURL}/posts/${id}/likes`);
  }

  /**
   *
   * @param id
   * @returns
   */
  public getFollowsBySellerId(id: number) {
    return this.http.get(`${this.apiURL}/sellers/${id}/follows`);
  }

  /**
   * @description posts made by the seller being viewed
   * @param id
   * @returns
   */
  public postsBySellerId(id: number) {
    return this.http.get(`${this.apiURL}/sellers/${id}/posts`);
  }

  /**
   * @description active products added by the seller being viewed
   * @param id
   * @param id
   * @returns
   */
  public productsBySellerId(id: number) {
    return this.http.get(`${this.apiURL}/sellers/${id}/products`);
  }
  /**
   *
   * @param id
   * @returns
   */
  public getPostById(id: number) {
    return this.http.get(`${this.apiURL}/posts/${id}`);
  }

  /**
   *
   * @param id
   * @param data
   * @returns
   */
  public editPostById(id: number, data: any) {
    return this.http.patch(`${this.apiURL}/posts/${id}`, data);
  }

  /**
   *
   * @param id
   * @returns
   */
  public deletePost(id: number) {
    return this.http.delete(`${this.apiURL}/posts/archive/${id}`);
  }

  public patchPost(id: number,mydata) {
    return this.http.patch(`${this.apiURL}/posts/archive/${id}`,mydata);
  }
  /**
   * @description returns the user's uploaded images so far
   * @returns
   */
  public getGalleryImages() {
    return this.http.get(`${this.apiURL}/users/gallery`);
  }

  /**
   *
   * @param productJSON
   * @returns
   */
  createGroup(productJSON) {
    return this.http.post(`${this.apiURL}/groups`, productJSON);
  }

  /**
   * @description get list of groups
   * @returns
   */
  getGroups() {
    return this.http.get(`${this.apiURL}/mygroups`);
  }

  /**
   *
   * @param sellerId
   */
  getGroupsBySellerId(sellerId: number) {
    return this.http.get(`${this.apiURL}/groups/seller/${sellerId}`);
  }

  /**
   * @description update group by id
   * @param id
   * @param groupBody
   * @returns
   */
  updateGroupById(id, groupBody) {
    return this.http.patch(`${this.apiURL}/groups/${id}`, groupBody);
  }

  /**
   *
   * @param id
   * @returns
   */
  public deleteGroup(id: number) {
    return this.http.delete(`${this.apiURL}/groups/${id}`);
  }

  /**
   *
   * @param exclude
   * @returns
   */
  public viewSimilarProducts(sellerId: number, exclude: string) {
    return this.http.get(`${this.apiURL}/products/${sellerId}/${exclude}`);
  }

  /**
   *
   * @param productId
   * @returns
   */
  public addToWishlist(productId: string, variantId: any) {
    return this.http.post(`${this.apiURL}/wishlists`, {
      productId: productId,
      variantId: variantId,
    });
  }

  public removeFromWishlist() {}

  /**
   *
   * @returns
   */
  public getWishlist() {
    return this.http.get(`${this.apiURL}/wishlists`);
  }

  /**
   *
   * @returns
   */
  getAddresses() {
    return this.http.get(`${this.apiURL}/addresses`);
  }
  /**
   *
   * @param id
   * @returns
   */
  getAddressInfo(id: number) {
    return this.http.get(`${this.apiURL}/addresses/${id}`);
  }

  /**
   *
   * @param data
   * @returns
   */
  saveAddressInfo(data) {
    return this.http.post(`${this.apiURL}/addresses`, data);
  }

  /**
   *
   * @param id
   * @param data
   * @returns
   */
  updateAddressInfo(id: number, data) {
    return this.http.patch(`${this.apiURL}/addresses/${id}`, data);
  }

  /**
   *
   * @param cart
   * @returns
   */
  public createCart(cart: any) {
    return this.http.post(`${this.apiURL}/carts`, cart);
  }

  /**
   *
   * @param cart
   * @returns
   */
  public getCart() {
    return this.http.get(`${this.apiURL}/carts`);
  }

  /**
   *
   * @param cart
   * @returns
   */
  public updateCart(bagId: string, cart: any) {
    return this.http.patch(`${this.apiURL}/carts/${bagId}`, cart);
  }

  /**
   *
   * @param bagId
   * @returns
   */
  public deleteCart(bagId: string) {
    return this.http.delete(`${this.apiURL}/carts/${bagId}`);
  }

  /**
   *
   * @param searchStr
   * @returns
   */
  public selectUsers(searchStr: string) {
    return this.http.get(`${this.apiURL}/users/search?searchStr=${searchStr}`);
  }

  /**
   *
   * @param order
   * @returns
   */
  public addOrder(order: Partial<Order>) {
    return this.http.post(`${this.apiURL}/orders`, order);
  }

  /**
   *
   * @returns
   */
  public getUserOrders(start: number = 0) {
    return this.http.get(`${this.apiURL}/orders?start=${start}&length=30`);
  }

  /**
   *
   * @returns
   */
  public getSellerOrders(filter: any = {}, start: number = 0) {
    return this.http.get(
      `${this.apiURL}/seller/orders?filter=${JSON.stringify(
        filter
      )}&start=${start}&length=30`
    );
  }

  /**
   *
   * @param filter
   * @param start
   * @returns
   */
  public getAllSellerOrders(filter: any = {}, start: number = 0) {
    return this.http.get(
      `${this.apiURL}/seller/orders?filter=${JSON.stringify(
        filter
      )}&start=${start}`
    );
  }
  /**
   * @description for seller payment calculation
   * @param filter
   * @param start
   * @returns
   */
  public getPaymentOrders(filter: any = {}, start: number = 0) {
    return this.http.get(
      `${this.apiURL}/seller/orders?filter=${JSON.stringify(filter)}&start=${start}&length=30`
    );
  }
  /**
   *
   * @param id
   * @returns
   */
  public getGroupById(id: string, start:number = 0, length:number = 30) {
    return this.http.get(`${this.apiURL}/groups/${id}?start=${start}&length=${length}`);
  }

  /**
   *
   * @param id
   * @param status
   * @returns
   */
  public updateOrderStatus(id: string, status: string, reason: string = '',subId) {
    let body = {};
    if (status == 'seller-cancel') {
      body = { reason: reason };
    }
    if(status === 'shipped' && reason){
      body = reason;
    }
    if(status === 'accept'){
      body = { order_num : reason , sub_order_num: subId};
    }
    return this.http.patch(
      `${this.apiURL}/orders/orderitems/${id}/${status}`,body);
  }

  /**
   *
   * @returns
   */
  public buyerStats() {
    return this.http.get(`${this.apiURL}/users/stats`);
  }

  /**
   *
   * @param id
   * @returns
   */
  public buyerStatsById(id: number) {
    return this.http.get(`${this.apiURL}/users/${id}/stats`);
  }

  /**
   *
   * @param post
   * @returns
   */
  public hidePost(post: Post) {
    return this.http.post(`${this.apiURL}/posts/${post.id}/archive`, {});
  }

  /**
   *
   * @param post
   * @returns
   */
  public turnOffSellerNotification(post: Post) {
    return this.http.post(
      `${this.apiURL}/sellers/${post.sellerId}/turn-off-notification`,
      {}
    );
  }

  /**
   *
   * @param post
   * @returns
   */
  public turnOnSellerNotification(post: Post) {
    return this.http.post(
      `${this.apiURL}/sellers/${post.sellerId}/turn-on-notification`,
      {}
    );
  }

  /**
   *
   * @param id
   * @returns
   */
  public getUserProfileById(id: number) {
    return this.http.get(`${this.apiURL}/users/${id}/profile`);
  }

  /**
   *
   * @param id
   * @param reason
   * @returns
   */
  cancelUserOrder(id: string, reason: string) {
    return this.http.patch(
      `${this.apiURL}/orders/orderitems/${id}/user-cancel`,
      { reason: reason }
    );
  }

  /**
   *
   * @param id
   * @returns
   */
  undeliveredOrder(id: string) {
    return this.http.patch(
      `${this.apiURL}/orders/orderitems/${id}/undelivered`,
      {}
    );
  }
  /**
   *
   * @param id
   * @param reason
   * @param address
   * @returns
   */
  returnUserOrder(id: string, reason: string, address: any) {
    return this.http.patch(
      `${this.apiURL}/orders/orderitems/${id}/return/initiate`,
      { reason: reason, address: address }
    );
  }

  /**
   *
   * @param id
   * @returns
   */
  confirmReturnUserOrder(id: string) {
    return this.http.patch(
      `${this.apiURL}/orders/orderitems/${id}/return/approve`,
      {}
    );
  }

  /**
   *
   * @param id
   * @returns
   */
  returnOrderShipped(id: string) {
    return this.http.patch(
      `${this.apiURL}/orders/orderitems/${id}/return/shipped`,
      {}
    );
  }

  /**
   *
   * @param id
   * @returns
   */
  returnOrderDelivered(id: string) {
    return this.http.patch(
      `${this.apiURL}/orders/orderitems/${id}/return/delivered`,
      {}
    );
  }

  /**
   *
   * @param id
   * @returns
   */
  refundedOrder(id: string) {
    return this.http.patch(
      `${this.apiURL}/orders/orderitems/${id}/refunded`,
      {}
    );
  }

  /**
   *
   * @param sellerId
   * @returns
   */
  getProductsBySellerId(sellerId: number, start:number = 0, length:number = 30) {
    return this.http.get(`${this.apiURL}/products/seller/${sellerId}?start=${start}&length=${length}`);
  }
  getProductsBySellerIds(sellerId: number, start:number = 0, length:number = 30,searchStr:string = '') {
    return this.http.get(`${this.apiURL}/productss/seller/${sellerId}?searchStr=${searchStr}&start=${start}&length=${length}`);
  }

  /**
   *
   * @returns
   */
  public getShippingStates() {
    return this.http.get(`${this.apiURL}/shipping/states`);
  }

  /**
   *
   * @returns
   */
  public getShippingCities(id: number) {
    return this.http.get(`${this.apiURL}/shipping/states/${id}/cities`);
  }

  /**
   *
   * @returns
   */
  public getWarehouses() {
    return this.http.get(`${this.apiURL}/warehouses`);
  }

  /**
   *
   * @param warehouse
   * @returns
   */
  public addWarehouse(warehouse: Warehouse) {
    return this.http.post(`${this.apiURL}/warehouses`, warehouse);
  }

  /**
   *
   * @param id
   * @returns
   */
  public deleteWarehouse(id: number) {
    return this.http.delete(`${this.apiURL}/warehouses/${id}`);
  }

  /**
   *
   * @param data
   * @returns
   */
  public getShippingRate(data) {
    return this.http.post(`${this.apiURL}/shipping/rate`, data);
  }

  /**
   * @description change user role
   * @param roleId
   * @returns
   */
  public changeRole(roleId: number) {
    return this.http.patch(`${this.apiURL}/users/switch-role/${roleId}`, {});
  }

  /**
   *
   * @param id
   * @returns
   */
  public addToAffiliation(id: string) {
    return this.http.post(`${this.apiURL}/products/${id}/affiliate`, {});
  }

  /**
   *
   * @param id
   * @returns
   */
  public removeFromAffiliation(id: string) {
    return this.http.patch(
      `${this.apiURL}/products/${id}/affiliate/remove`,
      {}
    );
  }

  /**
   *
   * @returns
   */
  public getAffiliatedProducts(start:number=0,length=10) {
    return this.http.get(`${this.apiURL}/products/affiliated?start=${start}&length=${length}`);
  }

  /**
   *
   * @param start
   * @returns
   */
  public getTrending(start: number = 0,length=30) {
    return this.http.get(
      `${this.apiURL}/posts/trendings?start=${start}&length=${length}`
    );
  }

  /**
   *
   * @param start
   * @returns
   */
  public getFollowing(start: number = 0) {
    return this.http.get(
      `${this.apiURL}/posts/followings?start=${start}&length=30`
    );
  }

  /**
   *
   * @param id
   * @param start
   * @description Getting posts list (id + remaining posts from that id)
   * @returns
   */
  public getSimilarPosts(id: number, start: number = 0) {
    return this.http.get(
      `${this.apiURL}/posts/${id}/similar?start=${start}&length=30`
    );
  }

  /**
   *
   * @param id
   * @returns
   */
  public getOrderDetails(id: string) {
    return this.http.get(`${this.apiURL}/orders/${id}`);
  }

  /**
   * @description print manifest label
   * @param id
   * @returns
   */
  public printManifestLabel(id: string) {
    return this.http.post(
      `${this.apiURL}/orders/orderitems/${id}/print-manifest`,
      {}
    );
  }

  /**
   * @description print shipment label
   * @param id
   * @returns
   */
  public printShipmentLabel(id: string) {
    return this.http.post(
      `${this.apiURL}/orders/orderitems/${id}/print-shipment-label`,
      {}
    );
  }
  /**
   *
   * @param postId
   * @param reason
   * @returns
   */
  public reportPost(
    postId: number,
    reason: string = 'The post has been reported'
  ) {
    return this.http.post(`${this.apiURL}/postreports`, {
      postId: postId,
      reason: reason,
    });
  }

  public initiatePayment(url: string, data: any) {
    return this.http.post(url, data);
  }

  /**
   *
   * @param query
   * @returns
   */
  public getGlobalSearch(query: string,start,lengths) {
    return this.http.get(`${this.apiURL}/search?searchStr=${query}&start=${start}&length=${lengths}`);
  }

  /**
   * @description get list of all affiliated products
   * @returns
   */

  // public getAllPromotedProducts(start: number = 0) {
  //   return this.http.get(`${this.apiURL}/products/promoted?start=${start}&length=30`);
  // }
  /**
   *
   * @param query
   * @returns
   */
  public getAllPromotedProducts(query: string = '',start: number = 0) {
    return this.http.get(`${this.apiURL}/products/promoted?searchStr=${query}&start=${start}&length=30`);
  }

  /**
   *
   * @returns
   */
  public getBuyerLikedPosts(start: number = 0) {
    return this.http.get(`${this.apiURL}/posts/likes?start=${start}&length=30`);
  }

  /**
   *
   * @param id
   * @returns
   */
  getPostsBySellerId(id: number, start: number = 0) {
    // pagination 
    return this.http.get(`${this.apiURL}/posts/seller/${id}/?start=${start}&length=30`);
  }

  /**
   *
   * @returns
   */
  public getBrands(searchStr: string = '') {
    return this.http.get(`${this.apiURL}/brands?searchStr=${searchStr}`);
  }

  /**
   *
   * @param data
   * @returns
   */
  public sendOTP(data: any) {
    return this.http.post(`${this.apiURL}/users/otp/sender`, data);
  }

  /**
   *
   * @param data
   * @returns
   */
  public verifyOTP(data: any) {
    return this.http.post(`${this.apiURL}/users/otp/verifying`, data);
  }

  /**
   * @description update featured post clicks
   * @param id
   * @returns
   */
  public updateFeaturedPostsClicks(id: number) {
    return this.http.post(`${this.apiURL}/featuredposts/${id}/click`, {});
  }

  /**
   * @description update featured post views
   * @param id
   * @returns
   */
  public updateFeaturedPostsViews(id: number) {
    return this.http.get(`${this.apiURL}/featuredposts/${id}`);
  }

  /**
   *
   * @param body
   * @returns
   */
  public loginWithGoogle(body: any) {
    return this.http.post(`${this.apiURL}/users/google-login`, body);
  }

  /**
   *
   * @returns
   */
  public getNotifications() {
    return this.http.get(`${this.apiURL}/notifications`);
  }

  public getUnreadNotificationsCount() {
    return this.http.get(`${this.apiURL}/notifications/count`);
  }
  /**
   * @description get information from the Google People API by passing google access token
   * @param auth_token
   */
  public getPeople(auth_token: string) {
    return this.http.get(
      `https://content-people.googleapis.com/v1/people/me?personFields=birthdays,genders,phoneNumbers&access_token=${auth_token}`
    );
  }

  /**
   * @description initiate the forgot password process
   * @param data
   * @returns
   */
  public forgotPasswordInitiate(data: any) {
    return this.http.post(`${this.apiURL}/users/forgotpassword/initiate`, data);
  }

  /**
   * @description complete forgot password  process
   * @param data
   * @returns
   */
  public forgotPasswordComplete(data: any) {
    return this.http.patch(
      `${this.apiURL}/users/forgotpassword/complete`,
      data
    );
  }

  /**
   *
   * @param categoryName
   * @returns
   */
  public viewSimilarByCategoryName(categoryName: string = '',start: number = 0) {
    return this.http.get(`${this.apiURL}/products/category/${categoryName}?start=${start}&length=30`);
  }

  public viewSimilarSellerProducts(id: number, start: number = 0, sellerId:number, catId:number) {
    return this.http.get(`${this.apiURL}/products/${id}/similarcategories?start=${start}&length=30&sellerId=${sellerId}&categoriesId=${catId}`);
  }

  /**
   *
   * @returns
   */
  public guestLogin() {
    return this.http.post(`${this.apiURL}/guest/login`, {});
  }

  public userVerify(data: any) {
    return this.http.post(`${this.apiURL}/users/verifyUser`, data);
  }

  public payments(data){
    return this.http.post(`${this.apiURL}/razorpay`,data);
  }

  public paymentVerify(data){
    return this.http.post(`${this.apiURL}/paymentVerify`,data);
  }

  public pinVerifypost(){
    return this.http.post(`${this.apiURL}/users/supportpin`,'');
  }

  public pinVerifyget(){ 
    return this.http.get(`${this.apiURL}/users/supportpin`);
  }

  public stripeApi(data){
   return this.http.post(`${this.apiURL}/create-payment-intent`,data)
  }

  public getPaymentData(shortkey,key,client_secret){
   return this.http.get(`https://api.stripe.com/v1/payment_intents/${shortkey}?key=${key}&is_stripe_sdk=false&client_secret=${client_secret}`)
  }

  public sendPaymentResponse(data)
  {
    return this.http.post(`${this.apiURL}/payment/stripe/getresponse`,data)
  }
  
  public follwIndexs()
  {
  return  this.http.get(`${this.apiURL}/posts/followIndex`)
  }

  public recentPost(start:number=0,length:number=30){
    return this.http.get(`${this.apiURL}/recent-posts?start=${start}&length=${length}`)
  }

  public postClaim(data){
    return this.http.post(`${this.apiURL}/claims`,data);
  }

  public orderStatusChange(id){
    return this.http.post(`${this.apiURL}/orderCancelled/${id}`,'')
  }

  public getClaimsSellerId(sellerId:any){
    return this.http.get(`${this.apiURL}/claims/seller/${sellerId}`)
  }

  public getClaimsOrderId(orderId:any){
    return this.http.get(`${this.apiURL}/claims/${orderId}`)
  }

  public getAllClaims(){
    return this.http.get(`${this.apiURL}/claims`)
  }

  public sendAdditionDetail(id:any,data){
    return this.http.patch(`${this.apiURL}/claims/additionalDetails/${id}`,data) 
  }

  public closeDisputeId(id:any,sendReason){
    return this.http.patch(`${this.apiURL}/claims/${id}/closedispute`,sendReason)
  }

  // public getShopifyproducts(pshopi){
  //   return this.http.post(`${this.apiURL}/externalshopkeys`,pshopi);
  // }

  public sellerPostInPostDetail(id, start:number=0, sellerID:number, catId:number,length=10){
    return this.http.get(`${this.apiURL}/posts/${id}/similarcategories?start=${start}&length=${length}&sellerId=${sellerID}&categoriesId=${catId}`)
  }

  public sellerSimilarInPostDetail(id, start:number=0, sellerID:number, catId:number, thirdCatId:number,length=10){
    return this.http.get(`${this.apiURL}/posts/${id}/similarcategoriesmixed?start=${start}&length=${length}&sellerId=${sellerID}&categoriesId=${catId}&thirdcategoriesId=${thirdCatId}`)
  }

  public getExploreCategories() {
    return this.http.get(`${this.apiURL}/explorecategories`);
  }

  public getSubExploreCategories(id: string) {
    return this.http.get(`${this.apiURL}/explorecategories/${id}/subcategories`);
  }

  public gpayResponse(data){
    return this.http.post(`${this.apiURL}/googlepaymentResponse`,data)
  }

  public paymentDetailsNew(){
    return this.http.get(`${this.apiURL}/customerpayment`)
  }

  public getCartProduct(id){
    return this.http.get(`${this.apiURL}/productscart/${id}?start=10`)
  }
  public deactivated(data){
    return this.http.post(`${this.apiURL}/users/deactivated`,data)
  }

  public delete(data){
    return this.http.post(`${this.apiURL}/users/delete`,data)
  }

  public Activate(id){
    return this.http.patch(`${this.apiURL}/users/${id}/activate`,'')
  }
  
  public getSellerCounts(sellerId:number){
    return this.http.get(`${this.apiURL}/productscount/${sellerId}`)
  }

  // proxyApiUrls  API use
  public getMesshoData(data){
    return this.http.post(`${this.proxyApiUrls}/product/${data}`,'')
  }

  public sendOtpProxy(data:number){
    return this.http.post(`${this.proxyApiUrls}/sendotp/${data}`,'')
  }

  public otpVerifyProxy(verifyUser: any) {
    return this.http.post(`${this.apiURL}/sendOtpVerify`, verifyUser);
  }

  public getAddressProxy(getUserAddress: { id: string, PimSid: string }) {
    return this.http.post(`${this.apiURL}/getaddress`,getUserAddress);
  }

  public GSTGetById(id){
    return this.http.get(`${this.apiURL}/taxes/${id}`)
  }
  public filterProduct(data){
    return this.http.post(`${this.apiURL}/filtered-products`,data.data)
  }
  public getNewPaymentOrders(filter: any , start: number = 0,id:any) {
    return this.http.get(
      `${this.apiURL}/seller/orders/${id}?filter=${filter}&start=${start}&length=30`
    );
  }

  public getSellerOrdersWithId(id,start: number = 0) {
    return this.http.get(
      `${this.apiURL}/sellerss/orderss/${id}?start=${start}&length=30`
    );
  }

  public getGroupNames( id:any ) {
    return this.http.get(`${this.apiURL}/groups/sellerIdName/${id}`);
  }

  public getGroupData( id:any, start:number = 0 ) {
    return this.http.get(`${this.apiURL}/grouper/${id}?start=${start}`);
  }

  public WhatsappOtpApi(number:number = 0 ) {
    return this.http.post(`${this.apiURL}/users/${number}/whatappsendotp`,'');
  }
  public getMyProductActive(searchStr: string = '', start:number = 0, length:number=30 ) {
    return this.http.get(`${this.apiURL}/myproducts/actives?searchStr=${searchStr}&start=${start}&length=${length}`);
  }

  public getAffiliated( start:number = 0 , length:number=30) {
    return this.http.get(`${this.apiURL}/products/affiliateded?start=${start}&length=${length}`);
  }

  public getProductsSearch(searchProducts:string,start: number = 0, length = 30) {
    return this.http.get(
      `${this.apiURL}/elasticsearch/products/search?searchStr=${searchProducts}&start=${start}&length=${length}`
    );
  }

  public getPostsSearch(searchPosts:string, start: number = 0, length = 30) {
    return this.http.get(
      `${this.apiURL}/elasticsearchsd/posts/search?searchStr=${searchPosts}&start=${start}&length=${length}`
    );
  }

  public getSellersSearch(searchSellers: string, start: number = 0, length = 30) {
    return this.http.get(
      `${this.apiURL}/elasticsearch/sellers/search?searchStr=${searchSellers}&start=${start}&length=${length}`
    );
  }
  public getCategoriesLikeStories() {
    return this.http.get(
      `${this.apiURL}/category/isVisibletrue`
    );
  }

  // public getAccountId() {
  //   return this.http.get('https://prod-shopy-assets.s3.ap-south-1.amazonaws.com/thirdpartykey/meesho_cookie.txtKarta');
  // }
  public catprodsearchs(data,start: number = 0, length = 30) {
    return this.http.post(
      `${this.apiURL}/catprodsearch?start=${start}&length=${length}`,data
    );
  }

  public verifyPromoCode(promocode:string,data:any) {
    return this.http.post(`${this.apiURL}/verifypromocode?promoCode=${promocode}`,data);
  }

  public paymentStatusUpdate(id:any) {
    return this.http.patch(`${this.apiURL}/orders/orderitems/${id}/orderPaid`,'');
  }

  createPayPayment(data: any) {
    return this.http.post(`${this.apiURL}/payments/initiate`, data);
  }

  payUResponse(data: any) {
    return this.http.post(`${this.apiURL}/payments/process`, data);
  }

  getShopifyCredincals(data){
    return this.http.get(`${this.apiURL}/externalshopkeys/${data}/details`)
  }
  InactivePosts(start=0,length=30){
    return this.http.get(`${this.apiURL}/myposts/inactive?start=${start}&length=${length}`)
  }
  ArchivePosts(start=0,length=30){
    return this.http.get(`${this.apiURL}/myposts/archive?start=${start}&length=${length}`)
  }

  // getAddedProduct(){
  //   return this.http.get(`${this.apiURL}/shopifyproducts`)
  // }

  // public getShopifyproducts(sellerId:any){
  //   return this.http.get(`${this.apiURL}/externalshopkeys/${sellerId}`)
  // }

  public externalShopkeys(data) {
    return this.http.post(`${this.apiURL}/externalshopkeys`,data);
  }
 
  public shopifyPost(data){
    return this.http.post(`${this.apiURL}/storeproducts`,data)
  }

  public postToProductImage(id: any, data: any): Observable<any> {
    return this.http.patch(`${this.apiURL}/orders/orderitems/${id}/add-packetid`, data);
  }

  public userBankDetailPost(data){
    return this.http.post(`${this.apiURL}/userbanks`,data);
  }

  public userBankDetailGet(){
    return this.http.get(`${this.apiURL}/userbanks/user`);
  }

  public prepaidOrder(data:any) {
    return this.http.post(`${this.apiURL}/orders/payment/success`,data);
  }
  public multipleShipmentLabel(data:any) {
    return this.http.post(`${this.apiURL}/orders/orderitems/print-shipment-labelall`,data);
  }

  public linkShortenPost(data:any) {
    return this.http.post(`${this.apiURL}/shorten`,data);
  }

  public linkShortenGet(shortCode:any) {
    return this.http.get(`${this.apiURL}${shortCode}`);
  }

  public getOrderData(id:any) {
    return this.http.get(`${this.apiURL}/orders/payload/${id}`);
  }

  public getAllProductOwenSeller(id:any) {
    return this.http.get(`${this.apiURL}/products/sellerall/${id}`);
  }
  public postUpdate(id: any, data: any): Observable<any> {
    return this.http.patch(`${this.apiURL}/postsseries/${id}`, '');
  }
  public multiplePrintManifest(data:any) {
    return this.http.post(`${this.apiURL}/orders/orderitems/print-shipping/manifest`,data);
  }
  public meeshoReviewApi(id,length) {
    return this.http.post(`${this.proxyApiUrls}/review_summary/${id}/${length}`,'')
  }
  public getSellerInsignts(sellerId,start,length,startDate,endDate) {
    return this.http.get(`${this.apiURL}/orders/analytics?sellerId=${sellerId}& start=${start}& length=${length}& startDate=${startDate}& endDate=${endDate}`);
  }
  // @post('/payment/{orderId}/success/{couponCode}')
  public getAllPaymentType() {
    return this.http.get(`${this.apiURL}/payments`);
  }
  public getOutOfStockProd(start = 0,length = 30) {
    return this.http.get(`${this.apiURL}/variants/check-quantity?start=${start}&length=${length}`);
  }

  public getOutOfStockProdQuantity() {
    return this.http.get(`${this.apiURL}/variants/count-zero-quantity`);
  }
  public getOrdersStatusCount(status) {
    return this.http.post(`${this.apiURL}/totalseller/orders/statusCount/${status}`,'');
  }
  public selectAllOrdersStatus(data) {
    return this.http.post(`${this.apiURL}/selectAll/ordersStatuschange`,data);
  }
  public printShipmentlabelAll(data) {
    return this.http.post(`${this.apiURL}/print-shipment-labelall/orders`,data);
  }
  public printManifestLabelAll(data) {
    return this.http.post(`${this.apiURL}/print-shipping/manifest/orders`,data);
  }

  orderDataPass = new BehaviorSubject<Array<any>>([]);
  feedsStatus = new BehaviorSubject<boolean>(false);
  isGetPostData = new BehaviorSubject<boolean>(false);
  tagsReset =  new BehaviorSubject<boolean>(true);
  isHomeActive = new BehaviorSubject<number>(0);
}  
