import { LocationStrategy } from '@angular/common';
import { Injectable, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import {
  IonRouterOutlet,
  IonApp,
  ActionSheetController,
  PopoverController,
  ModalController,
  MenuController,
  ToastController,
} from '@ionic/angular';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class AutoCloseOverlaysService {
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  subscription: any;
  private unsubscriber: Subject<void> = new Subject<void>();
  constructor(
    private actionSheetCtrl: ActionSheetController,
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private menu: MenuController,
    private router: Router,
    private toastController: ToastController,
    private location: LocationStrategy
  ) {}
  async trigger() {
    try {
      const element = await this.actionSheetCtrl.getTop();

      if (element) {
        element.dismiss();
        return;
      }
    } catch (error) {}

    // close popover
    try {
      const element = await this.popoverCtrl.getTop();
      if (element) {
        element.dismiss();
        return;
      }
    } catch (error) {}

    // close modal
    try {
      const element = await this.modalCtrl.getTop();
      if (element) {
        window.history.pushState('forward', null, '');

        history.pushState(null, '');

        fromEvent(window, 'popstate')
          .pipe(takeUntil(this.unsubscriber))
          .subscribe((_) => {
            history.pushState(null, '');
            // this.showErrorModal(`You can't make changes or go back at this time.`);
          });
        element.dismiss();

        return;
      }
    } catch (error) {}

    // close side menua
    try {
      const element = await this.menu.getOpen();
      if (element !== null) {
        this.menu.close();
        return;
      }
    } catch (error) {}
  }
  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
