import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-external-page',
  templateUrl: './external-page.component.html',
  styleUrls: ['./external-page.component.scss'],
})
export class ExternalPageComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private dialogRef: MatDialogRef<ExternalPageComponent >
  ) {
   }

  ngOnInit() {
      setTimeout(() => {
        window.open(this.data.productlink, '_blank');
        this.dialogRef.close();
      }, 1000);
  }

}
