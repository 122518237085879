import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class DashBoardGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private _authService : AuthService,
    private _sharedService : SharedService
  ){}
  canActivate( route : ActivatedRouteSnapshot, state: RouterStateSnapshot){
    if(this._authService!.isOnlyUserReg()){
      return true;
    }else {
      this.router.navigate(['profile/seller/intro']);
      return false;
    }
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    return this.canActivate(childRoute,state);
  }
}
