import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  color: string;
  col: string;
 
  constructor() { 
  }

  ngOnInit() {
    // this.getRandomColor();
    // this.getRandom();
       // Generate random colors for odd and even columns
      //  let oddColumnColor = this.getRandomColor();
      //  let evenColumnColor = this.getRandomColor();
   
       // Set CSS variables with the generated colors
      //  document.documentElement.style.setProperty('--odd-column-color', oddColumnColor);
      //  document.documentElement.style.setProperty('--even-column-color', evenColumnColor);
  }
  // getRandomColor(): string {
  //   let colors = ['#ACDDDE', '#CAF1DE', '#E1F8DC', '#FEF8DD', '#FFE7C7', '#F7D8BA'];
  //   let randomIndex = Math.floor(Math.random() * colors.length);
  //   this.color = colors[randomIndex];
  //   return this.color;
  // }
  // getRandom(): string {
  //   let colorss = [ '#E1F8DC', '#F7D8BA','#ACDDDE', '#FFE7C7', '#CAF1DE', '#FEF8DD'];
  //   let randomIndex = Math.floor(Math.random() * colorss.length);
  //   this.col = colorss[randomIndex];
  //   return this.col;
  // }
}
