import { Injectable } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ControllersService } from './controllers.service';
import { ResolverService } from './resolver.service';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  productDetails: any = {};
  productSizes: any = [];
  private addressTypes: Array<any> = [
    {
      name: 'Home',
      value: 1,
    },
    {
      name: 'Work',
      value: 2,
    },
    {
      name: 'Others',
      value: 3,
    },
  ];
  selectedMediaType: number = -1;
  previousUrl: string;
  previousToPreviousUrl: string;
  test: any = [];
  buyerInfo: any;
  constructor(
    public resolver: ResolverService,
    public controller: ControllersService,
    private router: Router,
     private location: Location,
     public auth: AuthService,
  ) {}

  public convertPriceToLocale(price) {
    return parseFloat(price).toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0,
    });
  }
  goToPrevSlide(slide: IonSlides) {
    slide.getActiveIndex().then(async (data) => {
      if (data.valueOf() == 0) {
        slide.slideTo((await slide.length()) - 1);
      } else {
        slide.slidePrev();
      }
    });
  }
  goToNextSlide(slide: IonSlides) {
    slide.getActiveIndex().then(async (data) => {
      if (data.valueOf() == (await slide.length()) - 1) {
        slide.slideTo(0);
      } else {
        slide.slideNext();
      }
    });
  }
  public addToWishlist(id: string, variantId: any, status: number) {
    this.resolver
      .addToWishlist(id, variantId)
      .toPromise()
      .then((data) => {
        // this.controller.presentToast(
        //   `${
        //     status == 1
        //       ? 'The product has been added to wishlist'
        //       : 'The product has been removed from wishlist'
        //   }`
        // );
      });
  }
  public getAddressTypes() {
    return this.addressTypes;
  }
  showTime(post?) {
    let createdDate = new Date(post?.created * 1000);
    if (!post?.created) {
      createdDate = new Date();
    }
    let todayDate = new Date();

    // with default values explicitly passed in.

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = todayDate.valueOf() - createdDate.valueOf();

    if (elapsed < msPerMinute) {
      return 'Just now';
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + ' minute(s) ago';
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + ' hour(s) ago';
    } else if (elapsed < msPerMonth) {
      return Math.round(elapsed / msPerDay) + ' day(s) ago';
    } else if (elapsed < msPerYear) {
      return Math.round(elapsed / msPerMonth) + ' month(s) ago';
    } else {
      return Math.round(elapsed / msPerYear) + ' year(s) ago';
    }
  }

  public validateDescription(desc) {
    let description = desc;
    description = description.replace(/[^A-Z0-9]/gi, '');
    for (let i = 0; i < description.length; i++) {
      let string = description.substring(i, i + 10);
      if (!isNaN(string)) {
        if (string.length == 10) {
          return false;
        }
      }
    }
    return true;
  }

  public isGuestMode() {
    return localStorage.getItem('guest');
  }
  public async showGuestMode() {
    {
      const alert = await this.controller.alertCtrl.create({
        header: 'Please Join Our Community to use this Feature',
        mode: 'ios',
        buttons: [
          // {
          //   text: 'Cancel',
          //   role: 'cancel',
          //   handler: () => {
          //   },
          // },
           {
            text: 'Sign Up/Login',
            role: '',
            handler: () => {
              const oldUrl = this.location.path();
              localStorage.setItem('Previous-url2', oldUrl);
              this.router.navigate(['loginRegistration']);
            },
          },
        ],
      });
      await alert.present();
    }
  }
  
  public raiseDispute(order: any, seller: any) {
    //let phone = "919768103825"
    let phone = environment.disputeNo;
    let product = order.productDetails;
    let item = order.delivery;
    let address = `*${item.road}, ${item.area}, ${item.city},${item.stateName}, ${item.pincode}*\n 
    Contact: *${item.phoneNo}*`;
    let text = `\nShop Name: *${seller.basicInfo.shopName}*\n
    Seller username: *${seller.basicInfo.userName}*\n
    is raising a dispute for\n
    Order number: *${order.orderId}*\n
    Product name: *${product.relatedInformation.name}* \n
    Product style no: *${product.relatedInformation.styleNo}* \n
    SKU: *${product.sku}*\n
    MRP: *INR ${product.pricing.mrp}*, SP: *INR ${product.pricing.sellingPrice}* \n
    Delivery charges: *INR ${product.pricing.deliveryCharges}*\n
    Quantity: *${product.pricing.quantity}*\n
    Total Amount: *INR ${product.pricing.totalAmount}* \n
    Carrier: *${order.shipping.logistic_name}* \n
    AWB Number: *${order.awbNumber}* \n
    Shipment Label:${order.shipmentLabel}\n
    Buyer Name: *${order.user.firstName} ${order.user.lastName}* \n
    Payment Mode: *${order.payment.paymentType}* \n
    Delivery Address: ${address} \n
    Product URL :${environment.feURL}/products/view/${order.sellerId}/${product.id} \n
    Order URL : ${environment.feURL}/dashboard/seller/orders/track/${order.id}\n
    `;
    window.open(
      `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURI(text)}`
    );
  }
  public async isUserlogOut() {
    {
      const alert = await this.controller.alertCtrl.create({
        header: 'Please Join Our Community to use this Feature',
        mode: 'ios',
        buttons: [
          // {
          //   text: 'Cancel',
          //   role: 'cancel',
          //   handler: () => {
          //   },
          // },
           {
            text: 'Sign Up/Login',
            role: '',
            handler: () => {
              const oldUrl = this.location.path();
              localStorage.setItem('Previous-url2', oldUrl);
              this.router.navigate(['loginRegistration']);
            },
          },
        ],
      });
      await alert.present();
    }
  }
  private dataSubject = new BehaviorSubject<any>(null);
  data$ = this.dataSubject.asObservable();

  updateData(data: any) {
    this.dataSubject.next(data);
  }
}
