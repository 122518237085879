import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { ControllersService } from 'src/app/services/controllers.service';
import { ResolverService } from 'src/app/services/resolver.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-gpay',
  templateUrl: './gpay.component.html',
  styleUrls: ['./gpay.component.scss'],
})
export class GpayComponent implements OnInit {
  timerText: string = 'Payment timed out after 5 minutes.';
  timerCount: any;
  timerExpired: string;
  hostName: string;
  feUrl: string;
  isTimeShow:boolean=true;
  paid: any;
  constructor(
    private resolver: ResolverService, 
    private router: Router, 
    @Inject(DOCUMENT) 
    private document: Document, 
    public controller: ControllersService,
    @Inject(MAT_DIALOG_DATA) 
    public data: any, 
    public dialogRef: MatDialogRef<GpayComponent>,
    public cart: CartService,
  ) {
    this.feUrl = environment.feURL;
  }

  ngOnInit() {
    this.hostName = document.location.protocol + '//' + document.location.hostname;
    if (this.data.gpay) {
      this.onBuyClicked();
    }
    let data = JSON.parse(localStorage.getItem('lineItem'));
    this.paid = data.shipping.paid;
  }

  // Global key for canMakepayment cache.
  canMakePaymentCache = 'canMakePaymentCache';

  /**
   * Launches payment request.
   */
  onBuyClicked() {
    if (!window.PaymentRequest) {
      return;
    }

    const supportedInstruments = [
      {
        supportedMethods: 'https://tez.google.com/pay',
        data: {
          pa: 'brainbotsdigitalprivatelimited@icici',
          pn: 'Brainbots Digital Pvt Ltd',
          tr: `${this.data.orderId}`,
          url: `${this.feUrl}/orders/confirmation`,
          mc: '5699',
          tn: 'Shopyglam Purchase',
        },
      }
    ];

    // Create order detail data.
    const details = {
      total: {
        label: 'Total',
        amount: {
          currency: 'INR',
          value: this.data.totalAmount,

        },
      },
      displayItems: [{
        label: 'Original Amount',
        amount: {
          currency: 'INR',
          value: this.data.totalAmount,
        },
      }],
    };
    const options = {
      requestShipping: true,
      requestPayerName: true,
      requestPayerPhone: true,
      requestPayerEmail: true,
      shippingType: 'shipping',
    };
    let request = null;
    try {
      request = new PaymentRequest(supportedInstruments, details);
    } catch (e) {
      return;
    }
    if (!request) {
      return;
    }

    var canMakePaymentPromise = this.checkCanMakePayment(request);
    canMakePaymentPromise.then((result) => {
      let dd =
      this.showPaymentUI(request, result);
    })
      .catch((err) => {
        // console.log('Error calling checkCanMakePayment: ' + err);
      });
  }

  /**
   * Checks whether can make a payment with Tez on this device. It checks the
   * session storage cache first and uses the cached information if it exists.
   * Otherwise, it calls canMakePayment method from the Payment Request object and
   * returns the result. The result is also stored in the session storage cache
   * for future use.
   *
   * @private
   * @param {PaymentRequest} request The payment request object.
   * @return {Promise} a promise containing the result of whether can make payment.
   */
  checkCanMakePayment(request) {
    // console.log("checkCanMakePayment===>", request)

    // Checks canMakePayment cache, and use the cache result if it exists.
    if (sessionStorage.hasOwnProperty(this.canMakePaymentCache)) {
      return Promise.resolve(JSON.parse(sessionStorage[this.canMakePaymentCache]));
    }

    // If canMakePayment() isn't available, default to assuming that the method is
    // supported.
    var canMakePaymentPromise = Promise.resolve(true);

    // Feature detect canMakePayment().
    if (request.canMakePayment) {
      canMakePaymentPromise = request.canMakePayment();
    }

    return canMakePaymentPromise
      .then((result) => {
        // Store the result in cache for future usage.
        sessionStorage[this.canMakePaymentCache] = result;
        return result;
      })
      .catch((err) => {
        // console.log('Error calling canMakePayment: ' + err);
      });
  }

  /**
   * Show the payment request UI.
   *
   * @private
   * @param {PaymentRequest} request The payment request object.
   * @param {Promise} canMakePayment The promise for whether can make payment.
   */
  showPaymentUI(request, canMakePayment) {
    // console.log("showPayemntUI====>", canMakePayment)

    // Redirect to play store if can't make payment.
    if (!canMakePayment) {
      this.redirectToPlayStore();
      this.dialogRef.close();
      return;
    }
    // start time
    this.startTimer();
    // Set payment timeout.
    let paymentTimeout = window.setTimeout(() => {
      window.clearTimeout(paymentTimeout);
      request.abort()
        .then(() => {
          // console.log('Payment timed out after 5 minutes.');
        })
        .catch(() => {
          // console.log('Unable to abort, user is in the process of paying.');
        });
    }, 5 * 60 * 1000); /* 5 minutes */

    request.show()
      .then((instrument) => {
        // window.clearTimeout(paymentTimeout);
        this.processResponse(instrument);  // Handle response from browser.
      })
      .catch((err) => {
      /// DOMException: Payment app returned an invalid result. Missing intent extras.
        let orderID = localStorage.getItem('id')
        if (this.paid != 'false') {
        this.resolver.orderStatusChange(orderID).subscribe((resp)=>{
          if (resp) {
            this.isTimeShow=false;
            this.controller.presentLoading('Order Canceled...');
          }
        })
      }
        console.log(err);
      });
  }

  /**
   * Process the response from browser.
   *
   * @private
   * @param {PaymentResponse} instrument The payment instrument that was authed.
   */
  processResponse(instrument) {
    var instrumentString = this.instrumentToJsonString(instrument);
    this.sendResponse(instrumentString, instrument);
    // fetch('/buy', {
    //   method: 'POST',
    //   headers: new Headers({ 'Content-Type': 'application/json' }),
    //   body: instrumentString,
    //   credentials: 'include',
    // })
    //   .then( (buyResult)=> {
    //     if (buyResult.ok) {
    //       return buyResult.json();
    //     }
    //     console.log('Error sending instrument to server.');
    //   })
    //   .then( (buyResultJson)=> {
    //     this.completePayment(
    //       instrument, buyResultJson.status, buyResultJson.message);
    //   })
    //   .catch( (err)=> {
    //     console.log('Unable to process payment. ' + err);
    //   });
  }

  /**
   * Notify browser that the instrument authorization has completed.
   *
   * @private
   * @param {PaymentResponse} instrument The payment instrument that was authed.
   * @param {string} result Whether the auth was successful. Should be either
   * 'success' or 'fail'.
   * @param {string} msg The message to log in console.
   */
  completePayment(instrument) {
    instrument.complete('success')
      .then(() => {
        document.getElementById('inputSection').style.display = 'none'
        document.getElementById('outputSection').style.display = 'block'
        document.getElementById('response').innerHTML =
        JSON.stringify(instrument, undefined, 2);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /** Redirect to PlayStore. */
  redirectToPlayStore() {
    if (confirm('Google Pay not installed, go to play store and install?')) {
      window.open('https://play.google.com/store/search?q=google+pay&c=apps', '_blank');
    };
      let orderID = localStorage.getItem('id')
      if (this.paid != 'false') {
        this.resolver.orderStatusChange(orderID).subscribe((resp)=>{
          if (resp) {
          this.controller.presentLoading('Order Canceled...');
          }
        })
      }
  }

  /**
   * Converts the shipping address into a JSON string.
   *
   * @private
   * @param {PaymentAddress} address The address to convert.
   * @return {string} The string representation of the address.
   */
  // addressToJsonString(address) {
  //   var addressDictionary = address.toJSON ? address.toJSON() : {
  //     recipient: address.recipient,
  //     organization: address.organization,
  //     addressLine: address.addressLine,
  //     dependentLocality: address.dependentLocality,
  //     city: address.city,
  //     region: address.region,
  //     postalCode: address.postalCode,
  //     sortingCode: address.sortingCode,
  //     country: address.country,
  //     phone: address.phone,
  //   };
  //   return JSON.stringify(addressDictionary, undefined, 2);
  // }

  /**
   * Converts the payment instrument into a JSON string.
   *
   * @private
   * @param {PaymentResponse} instrument The instrument to convert.
   * @return {string} The string representation of the instrument.
   */
  instrumentToJsonString(instrument) {
    // PaymentResponse is an interface, JSON.stringify works only on dictionaries.
    var instrumentDictionary = {
      methodName: instrument.methodName,
      details: instrument.details,
      // shippingAddress: this.addressToJsonString(instrument.shippingAddress),
      shippingOption: instrument.shippingOption,
      payerName: instrument.payerName,
      payerPhone: instrument.payerPhone,
      payerEmail: instrument.payerEmail,
    };
    return JSON.stringify(instrumentDictionary, undefined, 2);
  }

  /**
   * Update order details with shipping information.
   *
   * @private
   * @param {PaymentDetails} details The details for payment.
   * @param {Array} shippingOptions The shipping options.
   * @param {function} callback The callback to invoke.
   */
  updateShipping(details, shippingOptions, callback) {
    let selectedShippingOption;
    for (let i in shippingOptions) {
      if (shippingOptions[i].selected) {
        selectedShippingOption = shippingOptions[i];
      }
    }
  }

  startTimer() {
    const durationInMinutes = 5;
    const durationInSeconds = durationInMinutes * 60;

    let secondsRemaining = durationInSeconds;

    const timerInterval = setInterval(() => {
      const minutes = Math.floor(secondsRemaining / 60);
      const seconds = secondsRemaining % 60;
      this.timerCount = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`

      secondsRemaining--;

      if (secondsRemaining < 0) {
        clearInterval(timerInterval);
        this.timerExpired = 'Timer expired!'
      }
    }, 1000); // Update the timer every second (1000 milliseconds)
  }


  sendResponse(instrument:any, ins2) {
    // ==== instrument response ===
    //   "methodName": "https://tez.google.com/pay",
    //   "details": {
    //     "tezResponse": "{\"Status\":\"SUCCESS\",\"amount\":\"1.00\",\"txnRef\":\"1234ABCD\",\"toVpa\":\"brainbotsdigitalprivatelimited@icici\",\"txnId\":\"AXI35d52a4e316346aaa1a4e1a4ece9a9bf\",\"responseCode\":\"00\"}",
    //     "txnId": "AXI35d52a4e316346aaa1a4e1a4ece9a9bf",
    //     "responseCode": "00",
    //     "ApprovalRefNo": "",
    //     "Status": "SUCCESS",
    //     "txnRef": "1234ABCD",
    //     "TrtxnRef": "1234ABCD",
    //     "signature": "3044022051cfacb254633f72f49a435935575be909ad1a2fc9e7915a50521cf4e7f4b8a302205f672d58cc74336557657d3ffd145512303790dc8634e0cc3407fcfad9279542",
    //     "signatureKeyId": "PAYMENT_RESPONSE_V2"
    //   },
    //   "shippingOption": null,
    //   "payerName": null,
    //   "payerPhone": null,
    //   "payerEmail": null
    // }
    
    let ins=JSON.parse(instrument);    
    let gpayPayload = {
      txnId: ins?.details?.txnId,
      id: this.data.id,
      orderId: this.data.orderId,
      amount: this.data.totalAmount, // Amount in cents (e.g., $100.00 is 10000 cents)
      status: "SUCCESS", // or "processing", "requires_payment_method", or any other status
      payment_method_types: "credit_card", // Payment method type
      created: new Date(), // Date and time in ISO 8601 format
      receipt_email: "user@example.com",
      canceled_at: null, // If not canceled, you can set it to null
      cancellation_reason: null, // If not canceled, you can set it to null
      signature:ins?.details?.signature,
      signingMessage:ins?.details?.tezResponse,
      gpayResponse:instrument
    }
    this.resolver.gpayResponse(gpayPayload).subscribe((resp) => {
      if (resp) {
        this.completePayment(ins2);
        this.dialogRef.close();
        this.router.navigate([`/orders/confirmation`]);
        let orderID = localStorage.getItem('orderNo')
        if (localStorage.getItem('newPaymentType') == 'payment') {
        this.resolver.paymentStatusUpdate(orderID).subscribe((y)=>{
          localStorage.removeItem('orderNo');
          localStorage.removeItem('newPaymentType');
        })
        }
        this.cart.emptyBagCOD();
      }else{
        let orderID = localStorage.getItem('id')
        if (this.paid != 'false') {
          this.resolver.orderStatusChange(orderID).subscribe((resp) => {
            if (resp) {
              this.controller.presentLoading('Order Canceled...');
            }
          })
        }
      }
    })
  }
}
