import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AddPage } from '../pages/product/add/add.page';
import { ResolverService } from '../services/resolver.service';

@Injectable({
  providedIn: 'root'
})
export class CanDeactiveProductGuard implements  CanDeactivate<AddPage> {
  nextPath =  new BehaviorSubject<boolean>(false);
  getPath: boolean;
  constructor( public resolver: ResolverService){
  }
  canDeactivate(component: AddPage, currentRoute: ActivatedRouteSnapshot, 
                currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot)
              : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    this.nextPath.subscribe((resp)=>{
    this.getPath = resp;
    })
        if(this.getPath){
          return true;
          }else{
          return window.confirm('Are you sure you want to leave this page?');
        }
  }
}
