import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AutoCloseOverlaysService } from './services/auto-close-overlays.service';
import { ControllersService } from './services/controllers.service';
import { ResolverService } from './services/resolver.service';
import { CartService } from './services/cart.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  private backbuttonSubscription: Subscription;
  checkLoader: any;
  constructor(
    private autocloseOverlaysService: AutoCloseOverlaysService,
    private platform: Platform,
    public controller: ControllersService,
    public resolver: ResolverService,
    public    carts :CartService
  ) {
    // this.carts.skeleton.subscribe((x)=>{
    //  this.checkLoader= x;
    // })
    if (!window.localStorage.getItem('auth')) {
      //this.navCtrl.navigateRoot(['/login']);s
      this.resolver.guestLogin().subscribe((data: any) => {
        localStorage.setItem('auth', JSON.stringify(data));
        localStorage.setItem('guest', 'true');
        location.reload();
      });
    }
    this.platform.backButton.subscribeWithPriority(9999, () => {
      this.autocloseOverlaysService.trigger();
    });
  }
  @HostListener('document:ionBackButton', ['$event'])
  private overrideHardwareBackAction($event: any) {
    this.autocloseOverlaysService.trigger();
  }

  ngOnInit() {}

  ngOnDestroy() {}

  @HostListener('window:popstate', ['$event'])
  dismissModal() {
    this.autocloseOverlaysService.trigger();
  }
}