import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ResolverService } from '../services/resolver.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(private resolver: ResolverService, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      const currentPath = window.location.pathname;

      this.resolver.linkShortenGet(currentPath).subscribe((x: any) => {
        if (x && x.url) {
          // Redirect the browser to the real URL and prevent further routing
          window.location.href = x.url;
          resolve(false); // Prevent the route from activating
        } else {
          resolve(true); // Allow routing if no shortened URL
        }
      }, error => {
        // If there's an error (e.g. no shortened link found), allow routing
        resolve(true);
      });
    });
  }
}
