import { Injectable } from '@angular/core';
import {
  ActionSheetController,
  AlertController,
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular';
@Injectable({
  providedIn: 'root',
})
export class ControllersService {
  prefilledValue: any;
  selectedVal: any;
  selectedColor: any;
  priceArray: any = [];
  phoneNumber: any;
  private loading: HTMLIonLoadingElement | null = null;
  constructor(
    public actionSheetController: ActionSheetController,
    public loadingController: LoadingController,
    public alertCtrl: AlertController,
    public toastCtrl: ToastController,
    public modalCtrl: ModalController
  ) {}
  /**
   *
   * @param text
   */
  async presentLoading(
    text: string = 'Please wait...',
    duration: number = 2000
  ) {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: text,
      mode: 'ios',
      duration: duration,
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
  }

  /**
   *
   */
  async dismissAllLoaders() {
    let topLoader = await this.loadingController.getTop();
    while (topLoader) {
      if (!(await topLoader.dismiss())) {
        //   throw new Error('Could not dismiss the topmost loader. Aborting...');
      }
      topLoader = await this.loadingController.getTop();
    }
  }
  /**
   *
   * @param msg
   * @param type
   */
  async presentAlert(msg: string, type: string = 'Error') {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      mode: 'ios',
      header: type,
      message: msg,
      buttons: ['OK'],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
  }
  /**
   *
   * @param msg
   */
  async presentToast(msg: string, color: string = '') {
    const toast = await this.toastCtrl.create({
      message: msg,
      mode: 'ios',
      color: color,
      duration: 2000,
    });
    toast.present();
  }
  public displayJSON(data) {}

  sharePrefilled(data) {
    this.prefilledValue = data;
  }
  getPrefilled() {
    return this.prefilledValue;
  }

  shareSelected(val) {
    this.selectedVal = val;
  }
  getSelected() {
    return this.selectedVal;
  }

  setSelectedColor(evt) {
    this.selectedColor = evt;
  }
  getSelectedColor() {
    return this.selectedColor;
  }

  getPriceArray(data) {
    this.priceArray = data;
  }
  sendPriceArray() {
    return this.priceArray;
  }
  sendNumber(data) {
    this.phoneNumber = data;
  }
  getNumber() {
    return this.phoneNumber;
  }
  async presentLoader(message: string = 'Loading...'): Promise<void> {
    if (this.loading) {
      return; // Prevent multiple loaders
    }
    this.loading = await this.loadingController.create({
      message,
      spinner: 'crescent', // Customize spinner type
      backdropDismiss: false, // Prevent dismiss on tap
    });
    await this.loading.present();
  }

  async dismissLoader(): Promise<void> {
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
  }
}
