import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class UserAccountGuard implements CanActivate , CanActivateChild{
  constructor(
    private _authService : AuthService,
    private _sharedService : SharedService,
    private router: Router,
  ){}
  canActivate(route : ActivatedRouteSnapshot, state: RouterStateSnapshot){
    if(!this._authService.isuserLoggedIn()){
      return true;
    }else {
      // this.router.navigate(['loginRegistration']);
      this._sharedService.isUserlogOut();
      return false;
    }
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    return this.canActivate(childRoute,state);
  }
}