import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const byPassAuth = [
      '/users/login',
      '/users/registration',
      '/users/verify-email',
      '/users/forgotpassword/initiate',
      '/users/forgotpassword/complete',
      '/postalpincode',
      '/users/otp/send',
      '/users/otp/verify',
      '/users/google/signup',
      '/users/google/login',
      '/guest/login',
    ];

    let baseURLIndex = req.url.indexOf('/');
    let route = req.url.toString().replace(environment.apiURL, '');

    if (
      !byPassAuth.includes(route) &&
      !req.url.includes('https://securegw-stage.paytm.in/theia/api/v1/') &&
      !req.url.includes('https://content-people.googleapis.com/v1/people/me')
    ) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${AuthService.fetchCredentials().token}`,
        },
      });
    }

    return next.handle(req).pipe(
      catchError((err, caught) => {
        if (!byPassAuth.includes(route)) {
          if (err.status === 401) {
            this.handleAuthError();
            return of(err);
          }
          if (err.status === 403) {
            this.handleForbiddenError();
            return of(err);
          }
        }
        throw err;
      })
    );
  }
  handleForbiddenError() {}

  public handleAuthError() {
    localStorage.setItem('session_expired', 'true');
    this.auth.goToLogin();
  }
}
