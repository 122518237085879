import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  public _subject = new Subject<any>();
  public event = this._subject.asObservable();
  public selected: string = 'dashboard';
  public funnelName: string = '';

  public selectOption(component: string, name: string) {
    this.selected = component;
    this.funnelName = '';
    this.publish({ component: component, name: name });
  }
  public isActive(item: string) {
    return this.selected === item;
  }
  public publish(data: any) {
    this._subject.next(data);
  }
  public getSelected() {
    return this.selected;
  }
  /**
   *
   * @param name
   */
  public setFunnelName(name: string): void {
    this.funnelName = name;
  }
  /**
   *
   * @returns
   */
  public getFunnelName(): string {
    return this.funnelName;
  }
}
